import { Translations } from './utils/translations';
import {
  MEMBERS_AREA_DEF_ID,
  DAILY_TIMETABLE_WIDGET_CONTROLLER_ID,
  BOOK_BUTTON_WIDGET_CONTROLLER_ID,
  STAFF_LIST_WIDGET_CONTROLLER_ID,
  PageId,
  experiments
} from './constants';
import { addBookingsMembersArea, installMembersArea, membersAreaApplications } from './utils/members-area-actions';
import { addBookingsPagesAsPanel, createBookCheckoutState } from './utils/pages-panel-actions';
import {
  handleLightMigration,
  handleMigration,
  shouldProposeMigration
} from './utils/migrate-actions';
import { getCurrentVersion } from './utils/ci-actions';
import { proposeMigration } from './utils/migration-modals';
import {
  getStateBoxByBookingsAppBuilderWidget,
  getAllBookingsPages,
  getBookingsData,
  getPageData,
  isBookingsSplitted,
  onRemoveApp,
  removePage,
  getBookingsAppBuilderWidgetByChildComponentRef,
} from './utils/editor-sdk-actions';
import { BookingsAppBuilderStaticsUrls } from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';
import { DailyTimetableEditorComponentModel } from './editor-components/daily-timetable';
import { BookButtonEditorComponentModel } from './editor-components/book-button';
import { StaffListEditorComponentModel } from './editor-components/staff-list';
import { DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID, STAFF_LIST_WIDGET_DEV_CENTER_ID } from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';
import { isExperimentEnabled } from './utils/experiments';
import {
  generateActions,
  manageBookingsEvent,
  manageStateEvent,
  openBookButtonSettingsEvent,
  openDailyTimeTableSettingsEvent,
  openStaffListSettingsEvent,
  manageStaffEvent,
  openDailyTimeTableSettingsTextTabEvent,
  openStaffListTextSettingsEvent,
} from './utils/editor-actions';
import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk/dist/src';
import { withMembersArea, integrateMembersAreaApps } from '@wix/members-area-integration-kit';

export const createEditorScript = (withMembersAreaImprovements) => {
  let instance, appToken, sdk, locale, isAdi, isProposeMigrationNeeded;
  const bookingsDashboardPanel = 'bookings/scheduler/owner/offerings';
  const bookingsManageStaffDashboardPanel = 'bookings/staff';
  const deleteBookingsEvent = 'deleteBookings';
  const deletePageEvent = 'deletePage';
  const pageChangedEvent = 'focusedPageChanged';
  const widgetGfppClicked = 'widgetGfppClicked';
  const componentGfppClicked = 'componentGfppClicked';
  const componentAddedToStage = 'componentAddedToStage';
  const componentStyleChanged = 'componentStyleChanged';
  const gfppOriginDoubleClick = 'double_click';

  const editorTranslation = new Translations();
  let bookingsEditorComponentModels;

  const editorScript = {
    editorReady: async (_editorSDK, _appToken, options) => {
      const editorSdkAdapter = new EditorSdkAdapter(_editorSDK, _appToken);
      locale = await editorSdkAdapter.getEditorLanguage();
      const currentVersion = await getCurrentVersion(editorSdkAdapter);
      const bookingsData = await getBookingsData(_editorSDK, appToken);
      instance = bookingsData.instance;
      await editorTranslation.init(locale, currentVersion);
      const actions = generateActions(editorTranslation);
      const isBookableTimeTable = await isExperimentEnabled(experiments.BOOKABLE_TIME_TABLE);
      bookingsEditorComponentModels = {
        [DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID]: new DailyTimetableEditorComponentModel(editorSdkAdapter, editorTranslation, actions.manageBookingsAction, actions.manageStateAction, actions.openDailyTimeTableSettings, actions.openDailyTimeTableSettingsTextTab, isBookableTimeTable),
        [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: new BookButtonEditorComponentModel(editorSdkAdapter, editorTranslation, actions.manageBookingsAction, actions.bookButtonSettings),
        [STAFF_LIST_WIDGET_DEV_CENTER_ID]: new StaffListEditorComponentModel(editorSdkAdapter, editorTranslation, {
          manageStaffAction: actions
            .manageStaffAction,
          staffListSettingsAction: actions
            .openStaffListSettings,
          openStaffListTextSettingsAction: actions.openStaffListTextSettings
        })
      };
      if (withMembersAreaImprovements) {
        await integrateMembersAreaApps(membersAreaApplications(editorTranslation.t.bind(editorTranslation), currentVersion));
      }
      await editorSdkAdapter.registerToCustomEvents([componentAddedToStage, componentStyleChanged]);
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        isAdi = options.origin && options.origin.type === 'ADI';
        if (await isBookingsSplitted(sdk)) {
          await addBookingsPagesAsPanel(sdk, appToken);
        }
        // if (!isAdi && await isNoBookingsPagesInstalled(sdk)) {
        //   await handleBrokenNoPagesMigration(sdk, appToken);
        //   return Promise.resolve();
        // }
        if (options && options.firstInstall) {
          await addBookingsPagesAsPanel(sdk, appToken);
          if (!isAdi && !withMembersAreaImprovements) {
            await installMembersArea(sdk, appToken);
          }
        } else {
          await sdk.document.application.registerToCustomEvents(appToken, {eventTypes: [pageChangedEvent]});
          isProposeMigrationNeeded = shouldProposeMigration(sdk, isAdi, appToken, instance);
        }
        await createBookCheckoutState(sdk, appToken);
        resolve();
      });
    },
    getAppManifest: () => {
      return {
        controllersStageData: {
          [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
            default: bookingsEditorComponentModels[DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID].connection
          },
          [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: {
            default: bookingsEditorComponentModels[BOOK_BUTTON_WIDGET_CONTROLLER_ID].connection
          },
          [STAFF_LIST_WIDGET_CONTROLLER_ID]: {
            default: bookingsEditorComponentModels[STAFF_LIST_WIDGET_DEV_CENTER_ID].connection
          },
        },
        pages: {
          pageActions: {
            default: [
              'Pages_Actions_Page_Rename',
              {
                title: editorTranslation.t('bookings-pages.page.delete'),
                event: deletePageEvent,
                icon: 'deleteAction',
                type: 'page_remove',
              }
            ],
            bookCheckoutPage: []
          },
          pageSettings: {
            default: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                type: 'page_info'
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout'
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions'
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.seo'),
                type: 'seo'
              }
            ],
            bookCheckoutPage: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}&instance=${instance}`,
                helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                type: 'page_info'
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout'
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions'
              }
            ]
          },
          applicationSettings: {
            default: {
              displayName: editorTranslation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33'
            }
          },
          applicationActions: {
            default: {
              defaultValues: [{
                title: editorTranslation.t('bookings-pages.actions.manage'),
                event: manageBookingsEvent,
                icon: 'settingsAction'
              },
                {
                  title: editorTranslation.t('bookings-pages.actions.delete'),
                  event: deleteBookingsEvent,
                  icon: 'deleteRadio'
                }]
            }
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType',
              orderIndex: 2
            },
            bookCheckoutPage: {
              icon: 'bookingPageType',
              orderIndex: 1
            }
          },
        }
      }
    },
    onEvent: async ({eventType, eventPayload}, editorSDK) => {
      const editorSdkAdapter = new EditorSdkAdapter(editorSDK, appToken);
      async function openManageBookings() {
        await editorSDK.editor.openDashboardPanel(appToken, {url: bookingsDashboardPanel, closeOtherPanels: false});
        editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
      }

      async function openManageStaff() {
        await editorSdkAdapter.openStaffList('editor');
        editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
      }

      function focusOnStateBox(widgetComponentRef) {
        getStateBoxByBookingsAppBuilderWidget(editorSDK, appToken, widgetComponentRef).then(stateBoxComponentRef => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }

      function getWidgetComponentRef(componentRef) {
        getBookingsAppBuilderWidgetByChildComponentRef(editorSDK, appToken, componentRef).then(stateBoxComponentRef => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }
      async function openDailyTimeTableSettingsPanel(componentRef, tab?: 'text') {
        const innerPath = tab ? `/${tab}` : ``;

        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/daily-timetable/settings${innerPath}`;
        const title = editorTranslation.t('bookings.daily-timetable.settings.label.Header')
        const options = {
          width: 406
        }
        await editorSdkAdapter.openSettingsPanel(title, url, componentRef, null, instance, options)
      }

      async function openStaffListSettingsPanel(componentRef, tab = '') {
        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/staff-widget/settings/${tab}`;
        const title = editorTranslation.t('bookings.staff-list.settings.label.Header')
        const helpId = '8a5134e7-7689-4772-acc4-fb2a9830b54e';
        const options = {
          width: 406
        }
        await editorSdkAdapter.openSettingsPanel(title, url, componentRef, helpId, instance, options)
      }

      async function openBookButtonSettingsPanel(componentRef) {
        const url = `${BookingsAppBuilderStaticsUrls.BOOK_BUTTON_SETTINGS}`;
        const title = editorTranslation.t('bookings.book-button.settings.label.Header')
        const helpId = '0b25d4f6-6381-4d40-ac59-97a7d14cc7c2';
        await editorSdkAdapter.openSettingsPanel(title, url, componentRef, helpId, instance)
      }

      async function handleComponentAddedToStage() {
        const isTranslateAppBuilderWidgetContentEnabled = await isExperimentEnabled(experiments.TRANSLATE_APP_BUILDER_WIDGETS_CONTENT);
        if (isTranslateAppBuilderWidgetContentEnabled) {
          const addedComponentRef = eventPayload.compRef;
          const addedComponentWidgetId = await editorSdkAdapter.getWidgetId(addedComponentRef);
          if (bookingsEditorComponentModels[addedComponentWidgetId]) {
            await bookingsEditorComponentModels[addedComponentWidgetId].translateLabels(addedComponentRef);
          }
        }
      }

      async function handleComponentStyleChange() {
        const isLinkedStylesEnabled = await isExperimentEnabled(experiments.LINKED_STYLES);
        if (isLinkedStylesEnabled) {
          const styledComponentRef = eventPayload.compRef;
          const linkedBookingsWidget = await getParentWidget(styledComponentRef);
          if (linkedBookingsWidget) {
            const {widgetComponentRef, editorComponentModel} = linkedBookingsWidget;
            await editorComponentModel.handleStylesChange(widgetComponentRef, styledComponentRef);
          }
        }
      }

      async function getParentWidget(componentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {componentRef});
        for (const ancestor of ancestors) {
          const ancestorId = await editorSdkAdapter.getWidgetId(ancestor);
          if (bookingsEditorComponentModels[ancestorId]) {
            return {
              widgetComponentRef: ancestor,
              editorComponentModel: bookingsEditorComponentModels[ancestorId]
            };
          }
        }
        return null;
      }

      async function getParentWidgetCompRef(childComponentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {componentRef: childComponentRef});
        for (const ancestor of ancestors) {
          const data = await editorSDK.components.data.get(appToken, {componentRef: ancestor});
          if (data?.type === 'AppController') {
            return ancestor;
          }
        }
        return null;
      }

      async function handleDoubleClick() {
        let parentWidget;
        try {
          parentWidget = await getParentWidget(eventPayload.componentRef);
        } catch (e) {
          //todo try for book-button
        }
        if (parentWidget?.editorComponentModel.baseComponentModel.doubleClickAction) {
          eventPayload.id = parentWidget.editorComponentModel.baseComponentModel.doubleClickAction.actionId
        }
      }

      switch (eventType) {
        case componentAddedToStage:
          void handleComponentAddedToStage();
          break;
        case componentStyleChanged:
          void handleComponentStyleChange();
          break;
        case widgetGfppClicked:
          if (eventPayload.gfppOrigin === gfppOriginDoubleClick) {
            await handleDoubleClick();
          }
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings();
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(eventPayload.componentRef)
          }
          if (eventPayload.id === openBookButtonSettingsEvent) {
            void openBookButtonSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          break;
        case componentGfppClicked:
          const parentWidget = await getParentWidgetCompRef(eventPayload.componentRef);
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings();
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          if (eventPayload.id === openDailyTimeTableSettingsTextTabEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget, 'text');
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget);
          }
          if (eventPayload.id === openStaffListTextSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget, 'text');
          }
          break;
        case manageBookingsEvent:
          void openManageBookings();
          break;
        case manageStateEvent:
          focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          break;
        case deleteBookingsEvent:
          const bookingsPages = await getAllBookingsPages(editorSDK, appToken);
          const essentialPage = bookingsPages.find(page => page.tpaPageId === PageId.BOOKINGS_LIST);
          await removePage(editorSDK, appToken, essentialPage.id);
          break;
        case deletePageEvent:
          await removePage(editorSDK, appToken, eventPayload.pageRef.id);
          break;
        case pageChangedEvent:
          const pageData = await getPageData(sdk, appToken, eventPayload.pageRef);
          if (pageData.tpaPageId === PageId.SCHEDULER && isProposeMigrationNeeded && !(await isBookingsSplitted(editorSDK))) {
            await proposeMigration(sdk, appToken, locale, instance, editorTranslation);
          }
          break;
      }
    },
    handleAction: async (args) => {
      const type = args.type, payload = args.payload;
      try {
        switch (type) {
          case 'migrate':
            // if (!isAdi && await isNoBookingsPagesInstalled(sdk)) {
            //   await handleBrokenNoPagesMigration(sdk, appToken);
            //   return Promise.resolve();
            // }
            if (!(await isBookingsSplitted(sdk))) {
              return handleMigration(sdk, appToken, editorTranslation, !isAdi);
            } else {
              await handleLightMigration(sdk, appToken);
              return Promise.resolve();
            }
          case 'appInstalled':
            switch (payload.appDefinitionId) {
              case MEMBERS_AREA_DEF_ID: {
                return addBookingsMembersArea(sdk, appToken);
              }
              default:
                return Promise.resolve();
            }
          case 'removeApp':
            return onRemoveApp(sdk, appToken);
          default:
            return Promise.resolve();
        }
      } catch (e) {
        Promise.reject(e);
        throw e;
      }
    },
    getControllerPresets: () => {
      return Promise.resolve([]);
    }
  }

  return withMembersAreaImprovements ? withMembersArea(editorScript) : editorScript;
};
